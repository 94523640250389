<template>
  <v-col
    cols="12"
    md="8"
  >
    <v-card>
      <v-card-text>
        <h2 class="text-h5">
          Выберите капчу
        </h2>
        <v-select
          v-model="captchaInfo.service"
          :items="captchaTypes"
          :label="captchaInfo.service"
          class="mt-7"
          dense
          solo
        />
        <!-- АВТОРИЗАЦИЯ -->
        <div class="d-flex align-center justify-space-between">
          <div> Авторизация </div>
          <v-switch
            v-model="captchaInfo.authorization"
            :true-value="1"
            :false-value="0"
            hide-details
            color="primary"
            class="mt-0"
            inset
          />
        </div>
        <!-- РЕГИСТРАЦИЯ -->
        <div class="d-flex align-center justify-space-between">
          <div> Регистрация </div>
          <v-switch
            v-model="captchaInfo.registration"
            :true-value="1"
            :false-value="0"
            hide-details
            color="primary"
            class="mt-7 mb-7"
            inset
          />
        </div>
        <!-- ЗАБЫЛИ ПАРОЛЬ  -->
        <div class="d-flex align-center justify-space-between">
          <div> Забыли пароль </div>
          <v-switch
            v-model="captchaInfo.forgotPassword"
            :true-value="1"
            :false-value="0"
            hide-details
            color="primary"
            class="mt-0"
            inset
          />
        </div>
        <div class="d-flex justify-space-between">
          <!-- СОХРАНИТЬ -->
          <v-btn
            color="primary"
            class="mt-7"
            :loading="loader"
            @click="() => this.$store.dispatch('captcha/handleSaveCaptcha')"
          >
            Сохранить
          </v-btn>
          <!-- СБРОСИТЬ -->
          <v-btn
            color="secondary"
            class="mt-7"
            @click="() => this.$store.dispatch('captcha/getCaptchaInfo')"
          >
            Сбросить
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-alert
      v-if="successAlert"
      :icon="false"
      type="success"
      border="left"
      outlined
      text
      class="mt-3"
      dismissible
    >
      Успешно сохранены
    </v-alert>
    <v-alert
      v-if="errorAlert"
      :icon="false"
      type="error"
      border="left"
      outlined
      text
      class="mt-3"
      dismissible
    >
      Что то пошло не так
    </v-alert>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import captchaTypes from '@/constants/captcha/captchaTypes';

export default {
  name: 'Captcha',
  computed: {
    ...mapGetters({
      captchaInfo: 'captcha/captchaInfo',
      loader: 'captcha/loader',
      successAlert: 'captcha/successAlert',
      errorAlert: 'captcha/errorAlert',
    }),
    captchaTypes() {
      return captchaTypes;
    },
  },
  created() {
    this.$store.dispatch('captcha/getCaptchaInfo');
  },
};
</script>
